#contact {
  position: relative;
  border-top: 2px dashed orange;
  background: #54565e;
background: -webkit-linear-gradient(0deg, #54565e 0%, #3d3f45 50%, #202124 100%);
background: linear-gradient(0deg, #54565e 0%, #3d3f45 50%, #202124 100%);

  color: #ffffff;
}

#contact .scissorIcon{
  position: absolute;
  color: orange;
  font-size: 1.85rem;
  top: -1.1rem;
  left: 15%;
}

.contact_grp{

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.contact_media {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 2rem;
}

.contact_media a {
  font-size: 1.6rem;
}


.contact_grp img{
  width: clamp(1rem,100%,18rem);
  /* filter: invert(); */
}

.contact_grp .btn{
font-weight: 500;
border-width: 6px;
border-style: double;
border-color:rgb(255, 255, 255) ;
border-radius: 10px;
color: #ffffff;
background-color: #20212470;
padding-left: 1rem;
padding-right: 1rem;
}