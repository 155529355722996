.navbar {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.8rem 4.5%;

  background: rgba(32, 33, 36, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 166, 0, 0.788);
}

.navbar .logo {
  width: 6rem;
}

.navbar .smNav {
  display: flex;
  align-items: center;

 
}

.navbar .smNav .nav_items {
  position: absolute;
  width: 100%;
  top: 3.8rem;
  right: 0;

  padding: 1rem 0;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  background-color: rgba(32, 33, 36, 0.95);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 166, 0, 0.788);

}


.smNav .nav_items .btn{
  background-color: #ffa500;
  color: black;
  border: 0px;
}

.navbar .lgNav {
  display: none;
}

@media (min-width:760px) {
  .navbar .smNav{
    display: none;
  }

  .navbar .lgNav{
    display: inherit;
    
  }
  .navbar .lgNav .nav_items{
    display:  flex;
    align-items: center;
    gap: 1.25rem;
  }

  .navbar .lgNav .nav_items li:hover{
    color: #ffa500;
  }
  
}
