@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&display=swap);

.feature_grp{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1.85rem;
    gap: 1.85rem;
}

.feature_card{
    text-align: center;
    padding: 0rem 1rem;
    max-width: 18rem;
    margin: auto;

}


.feature_card p {
    font-size: 0.85rem;
    line-height: 1.6rem;
  }
  
  .feature_card svg {
    font-size: 1.6rem;
  }
  

#contact {
  position: relative;
  border-top: 2px dashed orange;
  background: #54565e;
background: linear-gradient(0deg, #54565e 0%, #3d3f45 50%, #202124 100%);

  color: #ffffff;
}

#contact .scissorIcon{
  position: absolute;
  color: orange;
  font-size: 1.85rem;
  top: -1.1rem;
  left: 15%;
}

.contact_grp{

  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.contact_media {
  display: flex;
  justify-content: center;
  align-items: center;

  grid-gap: 2rem;

  gap: 2rem;
}

.contact_media a {
  font-size: 1.6rem;
}


.contact_grp img{
  width: clamp(1rem,100%,18rem);
  /* filter: invert(); */
}

.contact_grp .btn{
font-weight: 500;
border-width: 6px;
border-style: double;
border-color:rgb(255, 255, 255) ;
border-radius: 10px;
color: #ffffff;
background-color: #20212470;
padding-left: 1rem;
padding-right: 1rem;
}
#hero {
  text-align: center;
  overflow: hidden;
}

#hero .container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.hero_text {
  margin: auto;

  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  line-height: 1.75rem;

  max-width: 46rem;
}
#hero h1{
  font-size: clamp(1.5rem, 1.012rem + 2.1687vw, 2.4rem);
}
#hero .btn {
  margin: auto;
}


#hero img {
  width: clamp(1rem, 100%, 25rem);
  margin: auto;
}

/* desktop */
@media (min-width: 1024px) {
  #hero {
    text-align: left;
  }
  #hero .container {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .hero_text{
    max-width: 32rem;
  }

  #hero .btn{
    margin: 0;
  }
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.8rem 4.5%;

  background: rgba(32, 33, 36, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 166, 0, 0.788);
}

.navbar .logo {
  width: 6rem;
}

.navbar .smNav {
  display: flex;
  align-items: center;

 
}

.navbar .smNav .nav_items {
  position: absolute;
  width: 100%;
  top: 3.8rem;
  right: 0;

  padding: 1rem 0;

  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;

  background-color: rgba(32, 33, 36, 0.95);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 166, 0, 0.788);

}


.smNav .nav_items .btn{
  background-color: #ffa500;
  color: black;
  border: 0px;
}

.navbar .lgNav {
  display: none;
}

@media (min-width:760px) {
  .navbar .smNav{
    display: none;
  }

  .navbar .lgNav{
    display: inherit;
    
  }
  .navbar .lgNav .nav_items{
    display:  flex;
    align-items: center;
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }

  .navbar .lgNav .nav_items li:hover{
    color: #ffa500;
  }
  
}

.project_grp {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
}
.project_card {
  width: clamp(1rem, 100%, 20rem);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  border: 1px solid var(--border-color);
  border-radius: 0.35rem;

}

.card_header {
  padding: 0.8rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
}
.card_header h4 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.card_links {
  display: flex;
  grid-gap: 0.4rem;
  gap: 0.4rem;
}
.card_links a {
  padding: 0.12rem 0.4rem;
  display: flex;
  grid-gap: 0.2rem;
  gap: 0.2rem;
  align-items: center;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.8125rem;
  border: 0.4px solid var(--border-color);
  border-radius: 10px;

  cursor: pointer;
}

.card_body {
  height: 8rem;
  /* border-bottom: 1px solid var(--border-color); */
  padding: 0.8rem 1rem;
  overflow: auto;

  /* scrollbar for firefox*/
  scrollbar-width: 5px;
  scrollbar-color: rgb(255, 166, 0) rgba(255, 111, 0, 0.237);
}

/* chrome, safari, opera scroll bar */
.card_body::-webkit-scrollbar {
  width: 5px;
  background-color: rgba(255, 111, 0, 0.237);
  border-radius: 10px;
}

.card_body::-webkit-scrollbar-thumb {
  background-color: rgb(255, 166, 0);
  border-radius: 10px;
}

.card_body p {
  /* font-family: "IBM Plex Sans", sans-serif; */
  font-size: 0.9125rem;
}

.card_footer {
  display: flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  font-size: 1.45rem;
  color: rgb(255, 166, 0);
  margin-top: 1rem;
  border-top: 1px solid var(--border-color);
  padding: 0.6rem 1rem;
}

#project .btn {
  margin: auto;
}



.skill_grp{

    margin: auto;

    display: flex;
    flex-wrap: wrap;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: space-around;

    max-width: 48rem;
}

.skill_grp img{
    width: 3rem;
    height: 3rem;
}


:root {
  --bg-color: #202124;
  --primary-text: #ffffff;
  --sec-text: #e8eaed;
  --third-text: #9aa0a6;
  --border-color: #5f6368;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  text-decoration: none;
  list-style: none;
  scroll-behavior: smooth;
}

body {
  background-color: #202124;
  background-color: var(--bg-color);
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  color: var(--primary-text);
}

a{
  all: unset;
  cursor: pointer;
}

.container{
  padding: 4rem 2rem;
}

.gradient-text{
  background: linear-gradient(to right, #ffc86e, #e36565);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.primary-text{
  color: #ffffff;
  color: var(--primary-text);
}

.sec-text{
  color: #e8eaed;
  color: var(--sec-text);
}

.third-text{
  color: #9aa0a6;
  color: var(--third-text);
}

.btn{
  border: 1px solid #5f6368;
  border: 1px solid var(--border-color);
  border-radius: 0.35rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.35rem 0.65rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;

  transition: ease-in 0.2s;

}

.btn:hover{
  background-color: #ffa500;
  color: black;
  border-color: transparent;
box-shadow: 0 0 32px 2.5px rgba(255, 166, 0, 0.41);
}

.sec_title{
  text-align: center;
  margin-bottom: 2rem;
}

.logo{
  font-family: 'Mrs Saint Delafield', cursive;
}

/* tablet */
@media (min-width:760px){
  .container{
    padding: 6rem 3rem;
  }
}



