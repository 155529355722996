@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&display=swap');

:root {
  --bg-color: #202124;
  --primary-text: #ffffff;
  --sec-text: #e8eaed;
  --third-text: #9aa0a6;
  --border-color: #5f6368;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  text-decoration: none;
  list-style: none;
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg-color);
  font-family: "Poppins", sans-serif;
  color: var(--primary-text);
}

a{
  all: unset;
  cursor: pointer;
}

.container{
  padding: 4rem 2rem;
}

.gradient-text{
  background: linear-gradient(to right, #ffc86e, #e36565);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.primary-text{
  color: var(--primary-text);
}

.sec-text{
  color: var(--sec-text);
}

.third-text{
  color: var(--third-text);
}

.btn{
  border: 1px solid var(--border-color);
  border-radius: 0.35rem;
  width: fit-content;
  padding: 0.35rem 0.65rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  transition: ease-in 0.2s;

}

.btn:hover{
  background-color: #ffa500;
  color: black;
  border-color: transparent;

  -webkit-box-shadow: 0 0 32px 2.5px rgba(255, 166, 0, 0.41);
-moz-box-shadow: 0 0 32px 2.5px rgba(255, 166, 0, 0.41);
box-shadow: 0 0 32px 2.5px rgba(255, 166, 0, 0.41);
}

.sec_title{
  text-align: center;
  margin-bottom: 2rem;
}

.logo{
  font-family: 'Mrs Saint Delafield', cursive;
}

/* tablet */
@media (min-width:760px){
  .container{
    padding: 6rem 3rem;
  }
}


